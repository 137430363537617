<template>
  <div class="form ali">
    <el-form :model="driverOptions" ref="driverOptions" size="mini">
      <el-form-item
        v-for="(item, index) in formObjDriver"
        :key="index"
        :label="item.label"
        :prop="item.name"
        :rules="item.rules"
        :placeholder="item.placeholder"
        style="margin: 0 auto; width: 350px; margin-bottom: 20px"
      >
        <!-- 单选框按钮组 -->

        <el-radio-group
          v-if="item.type === 'radio-group'"
          v-model="driverOptions[item.name]"
          size="large"
        >
          <el-radio-button
            v-for="(radio, index) in selectsOptions[item.name]"
            :key="index"
            :label="radio.label"
            >{{ radio.text }}</el-radio-button
          >
        </el-radio-group>

        <!-- 下拉框 -->

        <el-select
          v-if="item.type === 'select'"
          :placeholder="item.placeholder"
          v-model="driverOptions[item.name]"
        >
          <el-option
            v-for="(opt, index) in selectsOptions[item.name]"
            :key="index"
            :label="opt.label"
            :value="opt.value"
          ></el-option>
        </el-select>

        <!-- 普通输入框 -->

        <el-input
          v-if="item.type === 'input' || item.type === 'password'"
          v-model="driverOptions[item.name]"
          :type="item.type"
          style="width: 210px"
        ></el-input>
        <a
          v-if="item.name == 'projectId'"
          target="_blank"
          href="https://support.huaweicloud.com/api-cce/cce_02_0271.html"
          ><p class="tips">如何获取?</p></a
        >

        <el-form
          v-if="item.type === 'inlineInput'"
          :model="driverOptions[item.name]"
          ref="dynamicValidateForm"
        >
          <el-form-item
            v-for="(domain, index) in driverOptions[item.name]"
            :key="index"
          >
            <el-input v-model="domain.value"></el-input
            ><el-button
              @click.prevent="driverOptions[item.name].splice(index, 1)"
              >删除</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-button
              @click="
                driverOptions[item.name].push({
                  value: '',
                })
              "
              >新增</el-button
            >
          </el-form-item>
        </el-form>
      </el-form-item>
    </el-form>
    <div id="zhanwei"></div>
    <PrevNext @prev-click="onPrevClick" @next-click="onNextClick"></PrevNext>
  </div>
</template>

<script>
import formObj from "../../../assets/formObj/ali.js";
import PrevNext from "../../../components/Common/PrevNext.vue";
import { PROCESS_ACTIVE, DEFUALT_INPUT } from "../../../assets/constant.js";

export default {
  components: { PrevNext },
  // created() {
  //   this.$store.dispatch("setProcessActive", 2);
  // },
  // destroyed() {
  //   console.log('here: ' + this.$refs.driverOptions);
  // },

  created() {
    this.$store.dispatch("setProcessActive", PROCESS_ACTIVE.CLUSTER);
  },

  data() {
    return {
      formObjDriver: formObj.driverOptions,

      driverOptions: {
        clusterName:
          this.$store.getters.reqData?.driverOptions?.clusterName || "unamed",
        kubernetesVersion:
          this.$store.getters.reqData?.driverOptions?.kubernetesVersion ||
          "1.20.11-aliyun.1",
        clusterSpec:
          this.$store.getters.reqData?.driverOptions?.clusterSpec ||
          "ack.pro.small",
        clusterType:
          this.$store.getters.reqData?.driverOptions?.clusterType ||
          "ManagedKubernetes",
        // projectId: this.$store.getters.reqData?.driverOptions?.projectId || "",
        regionId:
          this.$store.getters.reqData?.driverOptions?.regionId ||
          "cn-huhehaote",
        vpcID:
          this.$store.getters.reqData?.driverOptions?.vpcID ||
          "",
        serviceCidr:
          this.$store.getters.reqData?.driverOptions?.serviceCidr ||
          "192.168.0.0/16",
        // serviceCidr:this.$store.getters.reqData?.driverOptions?.serviceCidr  || "",
        // clusterSpecPlatform:
        //   DEFUALT_INPUT.clusterSpecPlatform,
        // kubernetesProxyMode:
        //   DEFUALT_INPUT.kubernetesProxyMode,
        // containerNetWorkMode:
        //   DEFUALT_INPUT.containerNetWorkMode,
        numOfNodes:
          this.$store.getters.reqData?.driverOptions?.numOfNodes || "3",
        vswitchIDS: this.$store.getters.reqData?.driverOptions?.vswitchIDS || [
          {
            value: "",
          },
        ],
        // workerVswitchIDS: this.$store.getters.reqData?.driverOptions?.workerVswitchIDS  || "vsw-hp3v4u8v1uq2n4wpdrogl",
        // workerInstanceTypes: this.$store.getters.reqData?.driverOptions?.workerInstanceTypes  || "ecs.c6.xlarge",
        // workerSystemDiskCategory: this.$store.getters.reqData?.driverOptions?.workerSystemDiskCategory  || "cloud_ssd",
        // workerSystemDiskSize: this.$store.getters.reqData?.driverOptions?.workerSystemDiskSize  || "40",
        // workerInstanceChargeType: this.$store.getters.reqData?.driverOptions?.workerInstanceChargeType  || "PostPaid",
        natGateway:
          this.$store.getters.reqData?.driverOptions?.natGateway || false,
        disableRollback:
          this.$store.getters.reqData?.driverOptions?.disableRollback || true,
        loadBalancerSpec:
          this.$store.getters.reqData?.driverOptions?.loadBalancerSpec ||
          "slb.s2.small",
      },
      selectsOptions: {
        clusterSpec: [
          {
            label: "small",
            value: "ack.pro.small",
          },
          // {
          //   label: "200节点",
          //   value: "cce.s1.medium",
          // }
        ],
        clusterType: [
          {
            label: "ManagedKubernetes",
            value: "ManagedKubernetes",
          },
          // {
          //   label: "Turbo",
          //   value: "Turbo",
          // }
        ],
        regionId: [
          {
            label: "中国-呼和浩特",
            value: "cn-huhehaote",
          },
        ],

        // projectId: [
        //   {
        //     label: "数元灵",
        //     value: "0c8c4ca1bf80f2372f2cc003b2cf38bf",
        //   },
        // ],
        loadBalancerSpec: [
          {
            label: "slb.s2.small",
            value: "slb.s2.small",
          },
        ],
        serviceCidr: [
          {
            label: "192.168.0.0/16",
            value: "7a15c471-6d7c-48b5-835c-16846cbfbba1",
          },
        ],

        kubernetesVersion: [
          {
            label: "1.20.11-aliyun.1",
            label: "1.20.11-aliyun.1",
          },
        ],
        natGateway: [
          {
            text: "是",
            label: true,
          },
          {
            text: "否",
            label: false,
          },
        ],
        disableRollback: [
          {
            text: "是",
            label: true,
          },
          {
            text: "否",
            label: false,
          },
        ],

        // vpcID: [
        //   {
        //     text: "VPC网络",
        //     label: "vpc-hp3g4zgvv9sjftyj7j8nu",
        //   },
          // {
          //   text: "容器隧道网络",
          //   label: "6fc61f3e-9295-41a2-b15e-6aaffa294e3a",
          // },
        // ],
      },
    };
  },
  created() {
    //如果是后面步骤跳回来的，将vswitchIDS的结构['xxx']恢复为[{value:'xxx'}]以便渲染
    if (
      !!this.driverOptions.vswitchIDS.length &&
      typeof this.driverOptions.vswitchIDS[0] === "string"
    )
      this.driverOptions.vswitchIDS = this.driverOptions.vswitchIDS.map(
        (item) => {
          return { value: item };
        }
      );
  },
  methods: {
    onPrevClick() {
      this.$router.push("/form/aliAccess");
    },

    onNextClick(driverOptions) {
      this.$refs["driverOptions"].validate((valid) => {
        if (!!valid) {
          try {
            if (
              !this.driverOptions.vswitchIDS.length||!this.driverOptions.vswitchIDS.every((item) => item.value !== "")
            ) {
              this.$message({
                message: "请至少填写一个vswitchIDS，且不可包含值为空的项",
                type: "warning",
              });
              return;
            }
            //将vswitchIDS的[{value:'xxx'}]结构转换为['xxx']
            this.driverOptions.vswitchIDS = this.driverOptions.vswitchIDS.map(
              (item) => item.value
            );
            this.$store.commit("setDriverOptions", this.driverOptions);
            console.log(this.driverOptions);
            // axios.get("http://139.9.246.185:8080/api/driver/create").then((res) => {
            console.log("集群创建完成，即将进入节点创捷阶段...");
            console.log(JSON.stringify(this.$store.getters.reqData));
            // console.log('here: ' + this.$refs[driverOptions]);
            this.$router.push("/form/aliAccess/aliCluster/node");
            // });
          } catch (error) {
            console.log(error);
          }
        } else {
          ElMessage({
            showClose: true,
            message: "输入不符合规则，请重新输入",
            type: "error",
          });
        }
      });
    },
  },
};
</script>

<style scoped>
#zhanwei {
  height: 50px;
}
</style>